import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ad7e81f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex activity-tabs"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex column tab-content" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "content-for" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isPackage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activities, (a) => {
          return (_openBlock(), _createElementBlock("div", {
            key: a.entityHierarchyKey,
            class: _normalizeClass({ tab: true, selected: _ctx.activity?.entityHierarchyKey == a.entityHierarchyKey, flex: true, gap: true }),
            onClick: _withModifiers(($event: any) => (_ctx.select(a)), ["stop"])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(a.name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.contentFor(a)), 1)
            ])
          ], 10, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}